import React from 'react'
import LearnLiquity from '../liquity/LearnLiquity'

class Rabbit extends React.Component {

  render() {
    return (
      <LearnLiquity />
    )
  }
}

export default Rabbit
